import React, {CSSProperties} from 'react';
import {useDataRequestContext} from "@vivli/features/data-requests/infrastructure/context";
import {useActiveUser, useConfigService} from "@vivli/core/infrastructure/context";
import {useDataRequestPermissions} from "@vivli/features/data-requests/infrastructure/hook";
import {DataRequestStatusEnum} from "@vivli/features/data-requests/infrastructure/enum";
import {DataRequestTabComponent} from "./data-request-tab.component";

const tabsContainerStyle: CSSProperties = {
    minWidth: 250,
    maxWidth: 250,
    display: 'flex',
    flexDirection: 'column',
}

const bottomDivStyle: CSSProperties = {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    background: 'white',
    position: 'relative',
    zIndex: 14,
    display: 'flex',
    flexGrow: 2,
}

export const DataRequestTabsComponent = () => {
    const {dataRequest, showResearchResults, organizations} = useDataRequestContext();
    const config = useConfigService();
    const activeUser = useActiveUser();
    const isOrgAdmin = activeUser?.orgMemberships?.find(om => om.isOrgAdmin) !== undefined;
    const {shouldHideReviewers} = useDataRequestPermissions();
    const studies = dataRequest.requestedStudies;

    const isOrgAdminRestricted = () => {
        let isRestricted = false;
        const isDrOwner = dataRequest.userId === activeUser.userId;
        const isTeamMember = dataRequest.researchTeam.researchTeamDetails
            .find(x => x.userId === activeUser.userId);
        const isAdmin = (activeUser.isVivliAdmin || activeUser.isOperationsAdmin);

        if (!isAdmin && isOrgAdmin && !(isDrOwner || isTeamMember)) {
            const orgNames = {};
            organizations.forEach((org) => {
                orgNames[org.name] = true;
            });

            isRestricted = true;
            studies.forEach((study) => {
                if (orgNames[study.organizationName] || orgNames[study.approvalBodyOrgName]) {
                    isRestricted = false;
                }
            });
        }

        return isRestricted;
    };

    const getTabs = (): string[] => {
        let dataRequestTabs = ['Studies', 'Attachments', 'Request History'];

        const shouldShowStatusUpdate = activeUser.isVivliAdmin || isOrgAdmin;
        const shouldShowChat = config.chatEnabled && config.chatUri && config.chatUri.length > 0;
        const hideReviewers = shouldHideReviewers(dataRequest, organizations);
        const teamMember = dataRequest.researchTeam.researchTeamDetails.find(x => x.userId === activeUser.userId);

        if (!hideReviewers) {
            dataRequestTabs.push('Signed Agreements');
        }

        if (dataRequest.status === DataRequestStatusEnum.Fulfilled || dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled) {
            dataRequestTabs.push('Safety Concerns');
        }

        if (showResearchResults) {
            dataRequestTabs.push('Research Results');
        }

        if (shouldShowStatusUpdate) {
            dataRequestTabs.splice(1, 0, 'Status Update');
        }

        if (shouldShowChat) {
            dataRequestTabs.push('Chat');
        }

        if ((activeUser.isVivliAdmin || dataRequest.userId === activeUser.userId || teamMember) && !hideReviewers) {
            dataRequestTabs.push('Research Team');
        }

        if (dataRequest.status === DataRequestStatusEnum.Fulfilled ||
            dataRequest.status === DataRequestStatusEnum.Archived ||
            dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled) {
            dataRequestTabs.push('Research Environment');
            dataRequestTabs.push('Public Disclosures');
        }

        if (dataRequest.status !== DataRequestStatusEnum.Draft || activeUser.isVivliAdmin) {
            dataRequestTabs.push('Request Details/Print View');
        }
        if (dataRequest.status === DataRequestStatusEnum.Draft && activeUser.isVivliAdmin) {
            dataRequestTabs.push('Notifications');
        }

        if (isOrgAdminRestricted()) {
            dataRequestTabs = ['Request Details/Print View']
        }

        return dataRequestTabs;
    }

    return <div style={tabsContainerStyle}>
        {getTabs().map((title, i) =>
            <DataRequestTabComponent title={title} key={i}/>
        )}

        <div style={bottomDivStyle}/>
    </div>
}
